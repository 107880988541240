import axios from "axios";
import { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/assets";
import {
  Card,
  Button,
  Tooltip,
  SvgIcon,
  CircularProgress,
  Box,
} from "@mui/material";
import { Folder, File } from "lucide-react";
import { withInboundProcessGuard } from "src/hocs/with-role-based-guard.js";
import { Container, Stack } from "@mui/system";
import { CustomButton, CustomTypography } from "src/components/inputs/index.js";
import WestIcon from "@mui/icons-material/West";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddIcon from "@mui/icons-material/Add";
import {
  Check,
  CloseOutlined,
  Delete,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import { useAuth } from "src/hooks/use-auth.js";
import { Seo } from "src/components/seo.js";
const deployEnvironment = require("../../../deployEnvironment.js");

const API = deployEnvironment;
const STORAGE_KEY = "accessToken";

const Assets = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();

  const { items, itemsLoading, filesUploading, itemDeleting } = useSelector(
    (state) => state.assets
  );

  const [path, setPath] = useState("");
  const [hoveredElement, setHoveredElement] = useState("");

  const [addingDirectory, setAddingDirectory] = useState(false);
  const [newDirectoryName, setNewDirectoryName] = useState("");
  const newDirectoryRef = useRef(null);
  const [deleteItems, setDeleteItems] = useState(false);
  const [addItemModalVisible, setAddItemModalVisible] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [showWrongFileTypeAlert, setShowWrongFileTypeAlert] = useState(false);
  const [selectedFilesForUpload, setSelectedFilesForUpload] = useState(null);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!acceptedFiles.length) {
        setShowWrongFileTypeAlert(true);
        setTimeout(() => setShowWrongFileTypeAlert(false), 2000);
        return;
      }

      const formData = new FormData();

      acceptedFiles.forEach((file) => {
        formData.append("files", file);
      });

      formData.append("filePath", path);

      setSelectedFilesForUpload(formData);
    },
    [path]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    useFsAccessApi: false,
    webkitdirectory: true, // Allows folder selection
    directory: true, // Some browsers need this
    multiple: true, // Allow multiple files/folders
    // accept: { "image/*": [".jpg", ".jpeg", ".png"] }, // Restrict to images if needed
  });

  const updateData = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const pathFromUrl = urlParams.get("path");

    if (pathFromUrl) {
      setPath(pathFromUrl);
    }

    dispatch(thunks.getItems(pathFromUrl || ""));
  };

  useEffect(() => {
    updateData();
  }, [path]);

  useEffect(() => {
    if (newDirectoryRef?.current) {
      newDirectoryRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [addingDirectory]);

  const viewItem = async (filePath) => {
    dispatch(thunks.viewItem(filePath));
  };

  const uploadFolder = async (folderName) => {
    try {
      await dispatch(thunks.createFolder(folderName));

      updateData();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteFolder = async (folderPath) => {
    try {
      await dispatch(thunks.deleteFolder(folderPath));

      setItemToDelete(null);
      setDeleteModalOpened(false);
      setDeleteItems(false);
      updateData();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteItem = async (filePath) => {
    try {
      await dispatch(thunks.deleteItem(filePath));

      setItemToDelete(null);
      setDeleteModalOpened(false);
      setDeleteItems(false);
      updateData();
    } catch (error) {
      console.log(error);
    }
  };

  const navigateTo = (folder) => {
    setPath((prev) => {
      const newPath = prev ? `${prev}${folder}/` : `${folder}/`;

      window.history.pushState(
        { path: newPath },
        "",
        `?path=${encodeURIComponent(newPath)}`
      );

      return newPath;
    });
  };

  const goBack = () => {
    handleCloseAddDirectory();
    setDeleteItems(false);

    if (!path) return;

    const parts = path.split("/").filter(Boolean);
    if (parts.length > 0) {
      parts.pop();
    }

    const newPath = parts.length ? `${parts.join("/")}/` : "";

    setPath(newPath);

    window.history.replaceState(
      { path: newPath },
      "",
      newPath
        ? `?path=${encodeURIComponent(newPath)}`
        : window.location.pathname
    );

    dispatch(thunks.getItems(newPath));
  };

  const renderBackButton = () => {
    return (
      <Stack
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Stack
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            cursor: "pointer",
            textDecoration:
              hoveredElement === "back-button" ? "underline" : "none",
            padding: 5,
          }}
          onMouseEnter={() => setHoveredElement("back-button")}
          onMouseLeave={() => setHoveredElement()}
          onClick={goBack}
        >
          <KeyboardBackspaceIcon />
          <CustomTypography message="Back" style={{ fontSize: 12 }} />
        </Stack>
      </Stack>
    );
  };

  const renderCreateDirectoryButton = () => {
    return (
      <CustomButton
        style={{ background: "white" }}
        label="Create Directory"
        onClick={() => {
          setDeleteItems(false);
          setAddingDirectory(true);
        }}
        disabled={addingDirectory}
      />
    );
  };

  const renderDeleteItemButton = () => {
    return (
      <CustomButton
        style={{ background: "white", width: deleteItems ? "225px" : "150px" }}
        label={deleteItems ? "Cancel Delete Item" : "Delete Item"}
        onClick={() => {
          deleteItems ? setDeleteItems(false) : setDeleteItems(true);
          setAddingDirectory(false);
        }}
        disabled={path.split("/").length < 2}
      />
    );
  };

  const handleAddItem = () => {
    setAddItemModalVisible(true);
  };

  const handleUploadFiles = async () => {
    if (selectedFilesForUpload) {
      await dispatch(thunks.uploadFiles(selectedFilesForUpload));

      setSelectedFilesForUpload(null);
      setAddItemModalVisible(false);
      updateData();
    }
  };

  const renderAddItemsButton = () => {
    return (
      <CustomButton
        style={{ background: "white" }}
        label="Upload Item"
        onClick={handleAddItem}
      />
    );
  };

  const handleCloseAddDirectory = () => {
    setAddingDirectory(false);
    setNewDirectoryName("");
    setDeleteItems(false);
  };

  const renderButtonSection = () => {
    return (
      <Stack
        style={{
          right: 25,
          position: "fixed",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 10,
        }}
      >
        {renderAddItemsButton()}
        {renderDeleteItemButton()}
        {renderCreateDirectoryButton()}
      </Stack>
    );
  };

  const renderDropzone = (getRootProps, getInputProps, isDragActive) => {
    return (
      <Stack>
        <Stack
          p={5}
          {...getRootProps()}
          style={{
            width: "100%",
            height: "300px",
            border: "1px dashed lightgrey",
            fontFamily: "tmwCustomFont",
            textTransform: "uppercase",
            fontSize: 12,
            textAlign: "center",
            cursor: "pointer",
            userSelect: "none",
          }}
        >
          <input {...getInputProps()} />
          {!showWrongFileTypeAlert ? (
            isDragActive ? (
              <Stack
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "lightgrey",
                }}
              >
                <CloudUploadIcon style={{ height: "40px", width: "40px" }} />
                <Stack style={{ fontSize: 10 }}>
                  Click / Drag and drop to add folder or file
                </Stack>
              </Stack>
            ) : (
              <Stack
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "black",
                }}
              >
                <CloudUploadIcon style={{ height: "40px", width: "40px" }} />
                <Stack style={{ fontSize: 10 }}>
                  Click / Drag and drop to attach folder / file
                </Stack>
              </Stack>
            )
          ) : (
            <Stack
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "red",
              }}
            >
              Choose a valid input
            </Stack>
          )}
        </Stack>
        {selectedFilesForUpload && (
          <Stack
            style={{
              alignItems: "center",
              justifyContent: "flex-start",
              fontSize: 12,
              margin: "15px 10px",
              width: "100%",
              gap: 5,
            }}
          >
            <CustomTypography message="Selected files:" />
            <Stack>{renderFormDataFiles(selectedFilesForUpload)}</Stack>
          </Stack>
        )}
      </Stack>
    );
  };

  const renderFormDataFiles = (formData) => {
    const files = [];

    // Iterate over FormData entries
    formData.forEach((value, key) => {
      if (value && value.name && value.size) {
        files.push(value.name);
      }
    });

    return files.map((file, index) => (
      <Stack key={`file-nr-${index}`}>{file}</Stack>
    ));
  };

  const renderAddItemModal = () => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          backdropFilter: "blur(5px)",
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1100,
            minWidth: "700px",
          }}
        >
          <Container>
            <Stack
              elevation={12}
              style={{
                padding: "25px 25px 50px 25px",
                background: "white",
                border: "1px solid #E6E6E6",
              }}
            >
              <Stack style={{ gap: 25 }}>
                <Stack
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 5,
                  }}
                >
                  <CustomTypography title message="Upload Item to" />
                  {path || "/"}
                </Stack>
                {renderDropzone(getRootProps, getInputProps, isDragActive)}
              </Stack>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: 10,
                  margin: "35px 0 -15px 0",
                }}
              >
                <CustomButton
                  label="Cancel"
                  smallButton
                  onClick={() => {
                    setSelectedFilesForUpload(null);
                    setAddItemModalVisible(false);
                  }}
                />
                <CustomButton
                  label="Upload"
                  smallButton
                  greenButton
                  onClick={() => handleUploadFiles()}
                  disabled={!selectedFilesForUpload || filesUploading}
                  loader={
                    filesUploading && (
                      <CircularProgress
                        color="inherit"
                        size={10}
                        style={{ marginLeft: "4px" }}
                      />
                    )
                  }
                />
              </Stack>
            </Stack>
          </Container>
        </Box>
      </div>
    );
  };

  const renderDeleteItemModal = () => {
    const deletePrompt =
      itemToDelete.type === "file"
        ? "Are you sure you want to delete this file?"
        : "Are you sure you want to delete this folder and all of its contents?";

    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          backdropFilter: "blur(5px)",
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1100,
            minWidth: "500px",
          }}
        >
          <Container>
            <Stack
              elevation={12}
              style={{
                padding: "25px 25px 50px 25px",
                background: "white",
                border: "1px solid #E6E6E6",
              }}
            >
              <CustomTypography message={deletePrompt} />
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: 10,
                  margin: "35px 0 -15px 0",
                }}
              >
                <CustomButton
                  label="Cancel"
                  smallButton
                  onClick={() => {
                    setItemToDelete(null);
                    setDeleteModalOpened(false);
                  }}
                />
                <CustomButton
                  label="Delete"
                  smallButton
                  redButton
                  onClick={() => {
                    if (itemToDelete.type === "file") {
                      deleteItem(path + itemToDelete.item.name);
                    } else {
                      deleteFolder(path + itemToDelete.item.name);
                    }
                  }}
                  disabled={itemDeleting}
                  loader={
                    itemDeleting && (
                      <CircularProgress
                        color="inherit"
                        size={10}
                        style={{ marginLeft: "4px" }}
                      />
                    )
                  }
                />
              </Stack>
            </Stack>
          </Container>
        </Box>
      </div>
    );
  };

  return (
    <>
      <Seo title="Dashboard: Assets" />
      <Stack
        style={{
          margin: "25px 50px",
          fontFamily: "tmwCustomFont",
        }}
      >
        <Stack
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CustomTypography
            message="Tomorrow Assets"
            title
            style={{ margin: "10px 0 10px 0" }}
          />
        </Stack>
        {renderButtonSection()}
        <CustomTypography
          message={path || "/"}
          style={{ margin: "15px 0 0 0" }}
        />
        {!itemsLoading && (
          <CustomTypography
            message={
              items?.length
                ? items.length === 0
                  ? "0 Items"
                  : items.length === 1
                  ? "1 Item"
                  : `${items?.length} Items`
                : ""
            }
            style={{ margin: "15px 0 15px 0" }}
          />
        )}
        {!itemsLoading && path && (
          <Stack style={{ margin: "5px 0 5px 0" }}>{renderBackButton()}</Stack>
        )}
        {itemsLoading ? (
          <Stack
            style={{
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <CircularProgress color="inherit" size={35} />
          </Stack>
        ) : (
          <Stack
            style={{
              margin: "15px 30px",
              padding: "0 15px",
              gap: 10,
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
              gridAutoRows: "auto",
              alignItems: "start",
            }}
          >
            {!!items?.length &&
              items.map((item) => (
                <Stack
                  key={item.key}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: 10,
                    textDecoration:
                      hoveredElement === item.key ? "underline" : "none",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {item.isFolder ? (
                    <>
                      <Stack>
                        <Folder size={20} />
                      </Stack>
                      <Stack
                        style={{
                          fontSize: 14,
                          flexDirection: "row",
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() => {
                            handleCloseAddDirectory();
                            navigateTo(item.name);
                          }}
                          onMouseEnter={() => setHoveredElement(item.key)}
                          onMouseLeave={() => setHoveredElement()}
                        >
                          {item.name}
                        </span>
                        {path.split("/").length > 1 && deleteItems && (
                          <Delete
                            style={{
                              fontSize: 20,
                              cursor: "pointer",
                              marginLeft: "5px",
                            }}
                            onClick={() => {
                              setDeleteModalOpened(true);
                              setItemToDelete({ type: "folder", item });
                            }}
                          />
                        )}
                      </Stack>
                    </>
                  ) : (
                    <Stack style={{ flexDirection: "row", gap: 10 }}>
                      <Stack>
                        <File size={20} />
                      </Stack>
                      <Tooltip title={`View ${item.name}`}>
                        <span
                          style={{ cursor: "pointer", fontSize: 14 }}
                          onClick={() => {
                            handleCloseAddDirectory();
                            viewItem(item.key);
                          }}
                          onMouseEnter={() => setHoveredElement(item.key)}
                          onMouseLeave={() => setHoveredElement()}
                        >
                          {item.name}
                        </span>
                      </Tooltip>
                      {path.split("/").length > 1 && deleteItems && (
                        <Delete
                          style={{
                            fontSize: 20,
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                          onClick={() => {
                            setDeleteModalOpened(true);
                            setItemToDelete({ type: "file", item });
                          }}
                        />
                      )}
                    </Stack>
                  )}
                </Stack>
              ))}
            {!!addingDirectory && (
              <Stack
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                }}
                ref={newDirectoryRef}
              >
                <Stack>
                  <Folder size={20} />
                </Stack>
                <input
                  style={{ fontSize: 14 }}
                  value={newDirectoryName}
                  onChange={(e) => setNewDirectoryName(e.target.value)}
                />
                <Stack flexDirection="row">
                  <CloseOutlined
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={handleCloseAddDirectory}
                  />
                  <Check
                    style={{
                      color: newDirectoryName === "" ? "#E6E6E6" : "black",
                      cursor: newDirectoryName === "" ? "default" : "pointer",
                    }}
                    onClick={() => {
                      if (newDirectoryName !== "") {
                        uploadFolder(path + newDirectoryName);
                        handleCloseAddDirectory();
                      }
                    }}
                    disabled={newDirectoryName === ""}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
        )}
        {!items.length && !itemsLoading && !addingDirectory && (
          <Stack
            style={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "40vh",
            }}
          >
            <CustomTypography message="Empty directory" />
          </Stack>
        )}
        {addItemModalVisible && renderAddItemModal()}
        {deleteModalOpened && renderDeleteItemModal()}
      </Stack>
    </>
  );
};

export default withInboundProcessGuard(Assets);
