import axios from "axios";
const deployEnvironment = require("../../deployEnvironment.js");

const API = deployEnvironment;
const STORAGE_KEY = "accessToken";

class AssetsApi {
  // Items
  async getItems(fetchPath) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getItemsResponse = await axios.post(
      `${API}/api/spaces-api/list`,
      { prefix: fetchPath },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const items = getItemsResponse.data.objects;

    return new Promise((resolve, reject) => {
      try {
        if (!items) {
          reject(new Error("Unauthorized"));
          return;
        }
        resolve(items);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async createFolder(folderName) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(
      `${API}/api/spaces-api/createFolder`,
      { folderName },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  async viewItem(filePath) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const res = await axios.post(
      `${API}/api/spaces-api/download`,
      { filePath },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const url = res.data.url;

    return new Promise((resolve, reject) => {
      try {
        if (!url) {
          reject(new Error("Unauthorized"));
          return;
        }
        resolve(url);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async deleteFolder(folderPath) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(
      `${API}/api/spaces-api/deleteFolder`,
      { folderPath },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  async deleteItem(filePath) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(
      `${API}/api/spaces-api/deleteItem`,
      { filePath },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  async uploadFiles(selectedFilesForUpload) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(`${API}/api/spaces-api/upload`, selectedFilesForUpload, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

export const assetsApi = new AssetsApi();
