import { productAttributesApi } from "src/api/product-attributes";
import { slice } from "src/slices/product-attributes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Papa from "papaparse";
import * as XLSX from "xlsx";

// Linesheets
const getLinesheetList = () => async (dispatch) => {
  dispatch(slice.actions.linesheetListLoading());
  const response = await productAttributesApi.getLinesheetList();
  dispatch(slice.actions.linesheetListReceived(response));
};

// Linesheet Products
const getLinesheetProductsList = (linesheetId) => async (dispatch) => {
  dispatch(slice.actions.linesheetProductsListLoading());
  const response = await productAttributesApi.getLinesheetProductsList(
    linesheetId
  );
  dispatch(slice.actions.linesheetProductsListReceived(response));
};

// Edit Linesheet Product Row
const editLinesheetProductRow = (data) => (dispatch) => {
  dispatch(slice.actions.editLinesheetProductRow(data));
};

// Linesheet Products - Bulk
const updateLinesheetProductsList = (linesheetId, data) => async (dispatch) => {
  dispatch(slice.actions.linesheetProductsUpdateLoading());
  const response = await productAttributesApi.updateLinesheetProducts(
    linesheetId,
    data
  );
  dispatch(slice.actions.linesheetProductsUpdateLoadingReceived(response));
};

// Reset Linesheet Products Refresh
const linesheetItemsRefreshReset = () => (dispatch) => {
  dispatch(slice.actions.linesheetProductsRefreshReset());
};

// Import Excel
const uploadLinesheetProductsFromExcel = createAsyncThunk(
  "product-attributes/uploadLinesheetProductsFromExcel",
  async ({ linesheetId, formData }, { rejectWithValue }) => {
    try {
      const response =
        await productAttributesApi.uploadLinesheetProductsFromExcel(
          linesheetId,
          formData
        );
      return response.data;
    } catch (error) {
      throw rejectWithValue(error); // Return error payload
    }
  }
);

const uploadLinesheetProductsErrorReset = () => async (dispatch) => {
  dispatch(slice.actions.linesheetProductsImportErrorReset());
};

// Export Linesheet Products Attributes to Excel
const exportLinesheetProductsToExcel = (linesheetId) => async (dispatch) => {
  const result = await productAttributesApi.exportLinesheetProductsExcel(
    linesheetId
  );

  // Create a worksheet from the data
  const ws = XLSX.utils.json_to_sheet(result.items);

  // Create a workbook with the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Convert the workbook to an array buffer
  const excelFile = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  // Convert the array buffer to a blob
  const blob = new Blob([excelFile], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a URL for the blob
  const url = URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `${result.details.name} - ${result.details.season} Product Attributes export.xlsx`
  );

  // Trigger a click event to start the download
  link.click();

  // Clean up by revoking the URL object
  URL.revokeObjectURL(url);
};

// Export Linesheet Products Attributes to CSV
const exportLinesheetProductsToCSV = (linesheetId) => async (dispatch) => {
  const result = await productAttributesApi.exportLinesheetProductsExcel(
    linesheetId
  );

  const csv = Papa.unparse(result.items);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.setAttribute("href", url);
  link.setAttribute(
    "download",
    `${result.details.name} - ${result.details.season} Product Attributes export for NetSuite.csv`
  );
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Product Attributes Manufacturers Autocomplete Data
const getProductAttributesManufacturers = () => async (dispatch) => {
  dispatch(slice.actions.manufacturersAutocompleteDataLoading());
  const response = await productAttributesApi.getManufacturers();
  dispatch(slice.actions.manufacturersAutocompleteDataReceived(response));
};

// Create Product Attributes Manufacturer
const createProductAttributesManufacturer = (data) => async (dispatch) => {
  dispatch(slice.actions.createManufacturerLoading());
  const response = await productAttributesApi.createManufacturer(data);
  dispatch(slice.actions.createManufacturerReceived(response));
};

// Reset Refresh Manufacturers
const manufacturersRefreshReset = () => (dispatch) => {
  dispatch(slice.actions.resetManufacturersRefresh());
};

// Product Attributes Suppliers Autocomplete Data
const getProductAttributesSuppliers = () => async (dispatch) => {
  dispatch(slice.actions.suppliersAutocompleteDataLoading());
  const response = await productAttributesApi.getSuppliers();
  dispatch(slice.actions.suppliersAutocompleteDataReceived(response));
};

export const productAttributesThunks = {
  // Linesheets
  getLinesheetList,
  // Linesheet Products
  getLinesheetProductsList,
  editLinesheetProductRow,
  updateLinesheetProductsList,
  linesheetItemsRefreshReset,
  // Import Linesheet Products
  uploadLinesheetProductsFromExcel,
  uploadLinesheetProductsErrorReset,
  // Export Linesheet Products
  exportLinesheetProductsToExcel,
  exportLinesheetProductsToCSV,
  // Autocomplete data
  getProductAttributesManufacturers,
  // Manufacturer
  createProductAttributesManufacturer,
  manufacturersRefreshReset,
  // Suppliers
  getProductAttributesSuppliers,
};
