import { createSlice } from "@reduxjs/toolkit";
import { thunks } from "src/thunks/assets";

const assetsState = {
  items: [],
  itemsLoading: false,
  filesUploading: false,
  itemDeleting: false,
};

const reducers = {
  setItems(state, action) {
    state.items = action.payload;
  },
  setItemsLoading(state, action) {
    state.itemsLoading = action.payload;
  },
  setFilesUploading(state, action) {
    state.filesUploading = action.payload;
  },
  setItemDeleting(state, action) {
    state.itemDeleting = action.payload;
  },
};

export const slice = createSlice({
  name: "assets",
  initialState: assetsState,
  reducers,
});

export const { reducer } = slice;
