import { assetsApi } from "src/api/assets";
import { slice } from "src/slices/assets";

const getItems = (fetchPath) => async (dispatch) => {
  try {
    dispatch(slice.actions.setItemsLoading(true));

    const items = await assetsApi.getItems(fetchPath);

    dispatch(slice.actions.setItems(items));
    dispatch(slice.actions.setItemsLoading(false));
  } catch (err) {
    console.log(err);
  }
};

const createFolder = (folderName) => async (dispatch) => {
  try {
    await assetsApi.createFolder(folderName);
  } catch (err) {
    console.log(err);
  }
};

const viewItem = (filePath) => async () => {
  try {
    const fileUrl = await assetsApi.viewItem(filePath);

    // Open the file in a new tab
    window.open(fileUrl, "_blank", "noopener,noreferrer");
  } catch (err) {
    console.error("Error opening file:", err);
  }
};

const deleteFolder = (folderPath) => async (dispatch) => {
  try {
    dispatch(slice.actions.setItemDeleting(true));

    await assetsApi.deleteFolder(folderPath);

    dispatch(slice.actions.setItemDeleting(false));
  } catch (err) {
    console.log(err);
  }
};

const deleteItem = (filePath) => async (dispatch) => {
  try {
    dispatch(slice.actions.setItemDeleting(true));

    await assetsApi.deleteItem(filePath);

    dispatch(slice.actions.setItemDeleting(false));
  } catch (err) {
    console.log(err);
  }
};

const uploadFiles = (selectedFilesForUpload) => async (dispatch) => {
  try {
    dispatch(slice.actions.setFilesUploading(true));

    await assetsApi.uploadFiles(selectedFilesForUpload);

    dispatch(slice.actions.setFilesUploading(false));
  } catch (err) {
    console.log(err);
  }
};

export const thunks = {
  getItems,
  createFolder,
  viewItem,
  deleteFolder,
  deleteItem,
  uploadFiles,
};
